<template>
	<div>
		<div class="md-booking-toolbar" style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start; align-items:  center; align-content: center; height: 50px; background-color: #fff" >
			<div class="" style="width: 40px; text-align: center" >
				<a @click=" $router.go(-1)" style="padding: 10px 10px 10px 2px" >
					<md-icon>arrow_back</md-icon>
				</a>
			</div>
			<h3>
				{{lang.nueva_reserva.toUpperCase()}}
			</h3>
		</div>
		<md-dialog-alert :md-active.sync="alert.show" :md-content="alert.text"  :md-confirm-text="lang.cerrar" />


		<md-steppers :md-active-step.sync="active" md-vertical md-linear >
			<md-step id="first" :md-label="`${lang.ingreso} / ${lang.salida}`" :md-description="`& ${lang.disponibilidad}` " :md-editable="true" :md-done.sync="first">

				<div v-show="this.$store.getters.compartidas.categorias.length > 0">
					<md-field  :class="{'md-invalid': form_error.tipo}">
						<md-icon>workspaces</md-icon>
						<label>{{lang.tipo}}</label>
						<md-select v-model="selected.tipo" md-dense >
							<md-option value="" key="t1">{{lang.seleccione}}...</md-option>
							<md-option v-for="(tipo, index) in tipos" :value="index.toString()" :key="tipo.id">{{tipo.nombre}}</md-option>
						</md-select>
						<span class="md-error">{{lang.requerido}}</span>
					</md-field>
				</div>
				<div >
					<md-field  :class="{'md-invalid': form_error.categoria}">
						<md-icon>label</md-icon>
						<label >{{lang.categoria}}</label>
						<md-select v-model="selected.categoria" md-dense >
							<md-option value="">{{lang.seleccione}}...</md-option>
							<md-option v-for="(categoria, index) in categorias" :value="index.toString()" :key="categoria.id_categoria">{{categoria.nombre}}</md-option>
						</md-select>
						<span class="md-error">{{lang.requerido}}</span>
					</md-field>
				</div>

				<div>
					<md-field  :class="{'md-invalid': form_error.cuarto}">
						<md-icon>bed</md-icon>
						<label >{{lang.habitacion}}</label>
						<md-select v-model="selected.cuarto"  md-dense>
							<md-option value="">{{lang.seleccione}}...</md-option>
							<md-option v-for="(cuarto, index) in cuartos" :value="index.toString()" :key=" cuarto.id_cuarto">{{cuarto.nombre}}</md-option>
						</md-select>
						<span class="md-error">{{lang.requerido}}</span>
					</md-field>
				</div>

				<div class="md-layout md-gutter">
					<div class="md-layout-item">
						<div>
							<md-datepicker v-model="selected.ingreso" :class="{'md-invalid': form_error.ingreso}">
								<label>{{lang.ingreso}}</label>
								<span class="md-error">{{lang.requerido}}</span>
							</md-datepicker>
						</div>
					</div>

					<div class="md-layout-item">
						<md-datepicker v-model="selected.salida" :class="{'md-invalid': form_error.salida}">
							<label>{{lang.salida}}</label>
							<span class="md-error">{{lang.requerido}}</span>
						</md-datepicker>
					</div>
				</div>
				<div>
					<md-field >
						<md-icon>travel_explore</md-icon>
						<label >{{lang.agencia_canal}}</label>
						<md-select v-model="selected.agencia"  md-dense>
							<md-option value="">{{lang.ninguna}}</md-option>
							<md-optgroup  v-for="(canales, name) in agencias" :label="lang[name]" :key="name" v-if="canales.length > 0">
								<md-option :value="name+'-'+index.toString()" v-for="(canal, index) in canales" :key="index">{{canal.nombre}}</md-option>
							</md-optgroup>
						</md-select>
					</md-field>
				</div>

				<div class="md-layout md-gutter">
					<div v-if="details.noches"  class="md-layout-item" style="border: 1px dashed  dimgrey; font-size: 16px; display: flex; align-items: center; justify-content: center; height: 40px; margin-bottom: 1em">
						<div class = "text-center" style=" display: flex; align-items: center; ">
							<md-icon class = "md-large">night_shelter</md-icon> {{details.noches}}<span style="margin-left: 7px"> {{lang.noches}}</span>
						</div>
					</div>
				</div>

				<div class="md-layout md-gutter">
					<div class = "md-layout-item">
						<md-button class="md-raised secondary"@click="$router.go(-1)"> <md-icon>keyboard_arrow_left</md-icon>{{lang.cancelar}}</md-button>
					</div>
					<div class="md-layout-item" style="text-align: right">
						<md-button class="md-raised md-primary" @click="setDone('first')">{{lang.siguiente}} <md-icon>keyboard_arrow_right</md-icon></md-button>
					</div>
				</div>

			</md-step>

			<md-step id="second" :md-label="lang.datos_titular"  :md-description="lang.titular"  :md-error="secondStepError"  :md-editable="false" :md-done.sync="second">

				<div class="details">
					<div class="md-layout md-gutter ">
						<div  class="md-layout-item md-size-100" style="border-bottom: 1px dashed  dimgrey;">
							<div class=""><md-icon class="label">bed</md-icon>{{details.habitacion}}</div>
						</div>
						<div class="md-layout-item md-size-33">
							<div class = "text-center">
								<div> {{(details.ingreso) ? details.ingreso.format('YYYY-MM-DD') : 'N/A'}}</div>
								<div class="label">
									<md-icon class = "md-small ">flight_land</md-icon>
									<span>Check in</span>
								</div>
							</div>
						</div>
						<div class="md-layout-item md-size-33" style="border-left: 1px dashed  dimgrey;">
							<div class = "text-center">
								<div> {{(details.salida) ? details.salida.format('YYYY-MM-DD') : 'N/A'}}</div>
								<div class="label">
									<md-icon class = "md-small">flight_takeoff</md-icon>
									<span>Check out</span>
								</div>
							</div>
						</div>
						<div class="md-layout-item md-size-33" style="border-left: 1px dashed  dimgrey;">
							<div class = "text-center">
								<div> {{details.noches}}</div>
								<div class="label">
									<md-icon class = "md-small">night_shelter</md-icon>
									<span>{{lang.noches}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<p>
					<div class="md-layout md-gutter">
						<div class="md-layout-item md-size-50" >
							<md-field>
								<md-icon>person</md-icon>
								<label for = "adultos">{{lang.adultos}}</label>
								<md-select v-model = "selected.adultos" name = "adultos" id = "adultos" md-dense>
									<md-option v-for="index in adultos" :value="index" :key="index">{{index}}</md-option>
								</md-select>
							</md-field>
						</div>

						<div class="md-layout-item md-size-50" >
							<md-field>
								<md-icon>child_friendly</md-icon>
								<label for = "menores">{{lang.menores}}</label>
								<md-select v-model = "selected.menores" name = "menores" id = "menores" md-dense>
									<md-option value = "0">0</md-option>
									<md-option v-for="index in menores" :value="index" :key="index">{{index}}</md-option>
								</md-select>
							</md-field>
						</div>

						<div class="md-layout-item md-size-50" >
							<md-field :class="{'md-invalid': form_error.titular}">
								<label for="primer-nombre">{{lang.titular}}</label>
								<md-input name="primer-nombre" id="primer-nombre" autocomplete="given-name" v-model="selected.titular" :disabled="sending" />
								<span class="md-error">{{lang.requerido}}</span>
							</md-field>
						</div>
						<div class="md-layout-item md-size-50" >
							<md-field :class="{'md-invalid': form_error.primer_apellido}">
								<label for="primer-apellido">{{lang.primer_apellido}}</label>
								<md-input name="primer-apellido" id="primer-apellido" autocomplete="given-name" v-model="selected.primer_apellido" :disabled="sending" />
								<span class="md-error">{{lang.requerido}}</span>
							</md-field>
						</div>

						<div class="md-layout-item md-size-50" >
							<md-field>
								<label for="segundo-apellido">{{lang.segundo_apellido}}</label>
								<md-input name="segundo-apellido" id="segundo-apellido" autocomplete="given-name" v-model="selected.segundo_apellido" :disabled="sending" />
							</md-field>
						</div>

						<div class="md-layout-item md-size-50" >
							<md-field :class="{'md-invalid': form_error.telefono}">
								<label for="primer-apellido">{{lang.telefono}}</label>
								<md-input name="primer-apellido" id="primer-apellido" autocomplete="given-name" v-model="selected.telefono" :disabled="sending" />
								<span class="md-error">{{lang.requerido}}</span>
							</md-field>
						</div>

						<div class="md-layout-item" >
							<md-field :class="{'md-invalid': form_error.email}">
								<label for="segundo-apellido">{{lang.email}}</label>
								<md-input name="segundo-apellido" id="segundo-apellido" autocomplete="given-name" v-model="selected.email" :disabled="sending" />
								<span class="md-error">{{lang.email_invalido}}</span>
							</md-field>
						</div>
						<div class="md-layout-item md-size-100">
							<md-field>
								<label>{{lang.notas}}</label>
								<md-textarea v-model="selected.notas"></md-textarea>
							</md-field>
						</div>
					</div>
				</p>
				<div class="md-layout md-gutter">
					<div class = "md-layout-item">
						<md-button class="md-raised secondary"@click="back('first')"> <md-icon>keyboard_arrow_left</md-icon>{{lang.regersar}}</md-button>
					</div>
					<div class = "md-layout-item">
						<md-button class="md-raised md-primary"@click="setDone('second')">{{lang.siguiente}} <md-icon>keyboard_arrow_right</md-icon></md-button>
					</div>
				</div>

			</md-step>

			<md-step id="third" :md-label="lang.tarifas_noches"  :md-description="lang.total"  :md-error="secondStepError" :md-editable="false" :md-done.sync="third">

				<div class="md-layout md-gutter tarifas">
					<div v-if="tarifas_x_dia.tarifas"  class="md-layout-item md-size-100" style="margin-bottom: 15px; padding:7px; border: 1px dashed  dimgrey; font-size: 16px; display: flex; align-items: center; justify-content: center">
						<div class = "text-center" style=" display: flex; align-items: center; ">
							<md-icon class = "md-large">night_shelter</md-icon> {{details.noches}}<span style="margin-left: 7px"> {{lang.noches}}</span>
						</div>
					</div>

					<div class="md-layout-item md-size-50"  v-for="tarifa in fechas" >
						<md-field>
							<label>{{tarifa.fecha}}</label>
							<span class="md-prefix">{{currency}}</span>
							<md-input v-model="tarifa.precio" disabled></md-input>
						</md-field>
					</div>
				</div>

				<div class="md-layout md-gutter total" style="margin-bottom: 15px; padding:7px; border: 1px dashed  dimgrey; font-size: 16px; display: flex; align-items: center; justify-content: center">
					<div v-if="details.dcto > 0" class="md-layout-item md-size-100 dcto">
						{{lang.se_crea_dcto_1}} {{details.dcto}} {{lang.se_crea_dcto_2}} {{details.agencia.nombre}}
					</div>
					<div class="md-layout-item md-size-50"  >
						<md-icon>monetization_on</md-icon> {{lang.total_mayuscula}}
					</div>
					<div class="md-layout-item md-size-50" style="text-align: right" >
						{{currency}} {{details.total}}
					</div>
				</div>

				<div class="md-layout md-gutter">
					<div class = "md-layout-item">
						<md-button class="md-raised secondary"@click="back('second')"> <md-icon>keyboard_arrow_left</md-icon>{{lang.regersar}}</md-button>
					</div>
					<div class = "md-layout-item">
						<md-button class="md-raised md-primary"@click="setDone('third')">{{lang.finalizar}} <md-icon>keyboard_arrow_right</md-icon></md-button>
					</div>
				</div>

			</md-step>
		</md-steppers>
	</div>
</template>

<script>
	import {empty, request, avatar, Utility} from '../../../helpers';
	import moment from 'moment';
	export default {
		name: 'CalendarioCreateBooking',
		data: () => ({
			alert: {
				show : false,
				text : ''
			},
			selectedDate : '',
			active: 'first',
			first: false,
			second: false,
			third: false,
			secondStepError: null,
			item: {},
			sending: false,
			tarifas_x_dia: {},
			form_error :{
				tipo : false,
				categoria:false,
				cuarto : false,
				ingreso: false,
				salida : false,
				adultos : false,
				menores : false,
				titular :false,
				primer_apellido: false,
				segundo_apellido:false,
				telefono : false,
				email : false,
				agencia: false,
			},
			selected : {
				ingreso : null,
				salida  : null,
				tipo: '',
				categoria: '',
				cuarto  : '',
				noches  : 0,
				adultos: 1,
				menores: 0,
				titular : '',
				primer_apellido : '',
				segundo_apellido : '',
				total : 0,
				telefono: '',
				email :'',
				agencia : '',
				notas : ''

			}
		}),
		computed: {
			lang() {return this.$store.state.lang},
			currency(){return this.$store.state.establecimiento.moneda},
			agencias(){return this.$store.state.calendarData.canales},
			tipos(){
				if(!empty(this.$store.getters.habitaciones)) {

					if(this.$store.getters.compartidas.categorias.length === 0){
						let indexTipo = this.$store.getters.habitaciones.findIndex((h) =>  h.tipo == "privada" );
						this.selected.tipo = indexTipo.toString();
					}

					if(this.$store.state.create_data.tipo != '') {
						this.selected.tipo = this.$store.state.create_data.tipo;
						this.$store.state.create_data.tipo = '';
					}
					return this.$store.getters.habitaciones.map(t => ({id: t.id_tipo_habitacion, nombre: t.tipo}))
				}

				return  [];
			},
			categorias(){

				if(this.$store.state.create_data.categoria != '') {
					this.selected.categoria = this.$store.state.create_data.categoria;
					this.$store.state.create_data.categoria = '';
				}else{
					this.selected.categoria = '';
				}

				if(this.selected.tipo !== '') {
					return this.$store.getters.habitaciones[this.selected.tipo].categorias
				}

				return  [];
			},

			cuartos(){
				if(this.$store.state.create_data.cuarto != '') {
					this.selected.cuarto = this.$store.state.create_data.cuarto;
					this.$store.state.create_data.cuarto = '';
				}else{
					this.selected.cuarto = '';
				}

				if(this.selected.tipo !== '' && this.selected.categoria !== '') {
					return this.$store.getters.habitaciones[this.selected.tipo].categorias[this.selected.categoria].habitaciones
				}

				return  [];
			},

			adultos(){
				if(this.details.capacidad){
					return (this.details.capacidad - this.selected.menores)
				}
				return 0;
			},

			menores(){
				if(this.details.capacidad) {
					return (this.details.capacidad - this.selected.adultos)
				}
				return 0;
			},

			fechas(){

				if(!empty(this.tarifas_x_dia)) {
					this.selected.total = this.tarifas_x_dia.tarifas[0].total;
					return this.tarifas_x_dia.tarifas[0].fechas
				}

				return  [];
			},

			details(){

				let ingreso = null;
				let salida  = null;
				let noches = null;
				let categoria = {};
				let cuarto  = {};
				let tipo  = {};
				let habitacion = '';
				let capacidad = 0;
				let adultos = this.selected.adultos;
				let menores = this.selected.menores;
				let titular =  this.selected.titular;
				let primer_apellido =  this.selected.primer_apellido;
				let segundo_apellido = this.selected.segundo_apellido;
				let telefono = this.selected.telefono;
				let email = this.selected.email;
				let notas = this.selected.notas;
				let agencia = {};
				let dcto = 0;
				let total =0;

				if(this.selected.ingreso && this.selected.salida ) {
					ingreso = moment(this.selected.ingreso);
					salida = moment(this.selected.salida);
					noches =  salida.diff(ingreso, 'days');
				}

				if(this.selected.cuarto !==''){
					categoria = this.categorias[this.selected.categoria];
					cuarto  = this.cuartos[this.selected.cuarto];
					tipo  = this.cuartos[this.selected.cuarto].tipo;
					habitacion =  `${categoria.nombre} - ${cuarto.nombre} (${tipo})`;
					capacidad = categoria.capacidad_estandar;
				}

				if(this.selected.agencia != ''){
					let index = this.selected.agencia.split('-');
					agencia = this.agencias[index[0]][index[1]];
					dcto = parseFloat(agencia.descuento);
				}

				if(this.selected.total != ''){
					total = parseFloat(this.selected.total);
					if(dcto > 0 && total > 0){
						total = total - (total * (dcto / 100));
					}
				}

				return {
					ingreso,
					salida,
					noches,
					categoria,
					cuarto,
					tipo,
					habitacion,
					capacidad,
					adultos,
					menores,
					titular,
					primer_apellido,
					segundo_apellido,
					email,
					telefono,
					agencia,
					total,
					dcto,
					notas
				}
			}
		},
		methods: {
			back(key, current){
				this[key]= false;
				this.active =key;
			},
			async setDone (step) {
				let error  = 0;
				switch (step) {
					case 'first' :
						this.reset();
						console.log(this.selected.tipo);
						if(this.selected.tipo == ''){
							this.form_error.tipo  = true;
							error++;
						}

						if(this.selected.categoria === ''){
							this.form_error.categoria = true;
							error++;
						}

						if(this.selected.cuarto === ''){
							this.form_error.cuarto = true;
							error++;
						}

						if(empty(this.selected.ingreso)){
							this.form_error.ingreso = true;
							error++;
						}

						if(empty(this.selected.salida)){
							this.form_error.salida  = true;
							error++;
						}

						if(this.details.noches < 1){
							this.form_error.ingreso = true;
							this.form_error.salida  = true;
							error++;

							this.alert.show = true;
							this.alert.text = this.lang.seleccione_fecha_rango;
							this.alert.btn = this.lang.cerrar;

						}

						if(error  == 0){
							let data ={
								ingreso : this.details.ingreso.format('DD/MM/YYYY'),
								salida : this.details.salida.format('DD/MM/YYYY'),
								room_id : this.details.cuarto.id_cuarto
							};

							this.$store.state.loading = true;
							let response  = await request(base_url+'/api/disponibilidad?jwt='+this.$store.state.jwt, Utility.convertModelToFormData(data));
							this.$store.state.loading = false;
							if(response.ok){
								response = response.r;
								if(typeof response.data == 'object'){
									if(response.data.status != 'ok'){
										this.alert.text = response.data.message;
										this.alert.show = true;
										return
									}

									this.first = true;
									this.active = 'second';
								}
							}
						}

						break;
					case 'second':

						if(this.selected.titular.length == 0){
							this.form_error.titular  = true;
							error++;
						}

						if(this.selected.primer_apellido.length == 0 ){
							this.form_error.primer_apellido = true;
							error++;
						}

						if(this.selected.email.length > 0 ){
							let re = /\S+@\S+\.\S+/;
							if(!re.test(this.selected.email)){
								error++;
								this.form_error.email = true;
							}

						}



						if(error == 0){

							let rooms  = {
								rooms : [{
									desde   : this.details.ingreso.format('DD/MM/YYYY'),
									hasta   : this.details.salida.format('DD/MM/YYYY'),
									personas: this.details.adultos,
									menores : this.details.menores,
									noches  : this.details.noches,
									idRoom  : this.details.cuarto.id_cuarto
								}]
							};
							this.$store.state.loading = true;
							let response  = await request(base_url+'/reserva/getTarifa_x_dia?jwt='+this.$store.state.jwt, Utility.convertModelToFormData(rooms));
							this.$store.state.loading = false;
							if(response.ok && typeof response.r.data == 'object' ) {
								this.tarifas_x_dia = response.r.data;
								this.second = true;
								this.active = 'third';
							}
						}

						break;

					case 'third':
						let form = {
							"idHabitacion": this.details.cuarto.id_cuarto,
							"quien": "persona",
							"desde":  this.details.ingreso.format('DD/MM/YYYY'),
							"hasta":  this.details.salida.format('DD/MM/YYYY'),
							"selectCantidadPersonas": this.details.adultos,
							"selectCantidadMenores": this.details.menores,
							"selectConfProv": "1",
							"date_bloquear_reserva": "",
							"idAgencia": (this.details.agencia.id_agencia)??'',
							"idplan": "",
							"idCliente": "",
							"noeditar": "",
							"buscar_por": "",
							"busqueda": "",
							"nacionalidad": "",
							"documento": "",
							"tipoDocumento": "",
							"fechaNacimiento": "",
							"nombre": this.details.titular,
							"primerApellido": this.details.primer_apellido,
							"segundoApellido": this.details.segundo_apellido,
							"direccion": "",
							"genero": "",
							"telefono": this.details.telefono,
							"email": this.details.email,
							"ocupacion": "",
							"proDes": "",
							"paisProcedencia": "",
							"ciudadProcedencia": "",
							"paisDestino": "",
							"ciudadDestino": "",
							"motivo_viaje": "",
							"detalles_motivo_viaje": "",
							"notasn": this.details.notas,
							/*"crearReservasTarifaItemPrecio": {
							 "87867": [
							 "270",
							 "360",
							 "450",
							 "540",
							 "270"
							 ]
							 },*/
							"crearReservasTarifaItem": "0",
							"dtoTipo": "valor",
							"dtoValor": "",
							"dtoPorcentaje": "",
							"hacerCheckin": "0"
						};
							
						this.$store.state.loading = true;
						let response  = await request(base_url+'/reserva/crearReserva?view=json&jwt='+this.$store.state.jwt, Utility.convertModelToFormData(form));
						this.$store.state.loading = false;
						if(response.ok && typeof response.r.data == 'object' ) {
							this.$router.push({path:`/Reserva/${response.r.data.idReserva}/detalle`})
						}
						break;
				}
			},
			setError () {
				this.secondStepError = 'This is an error!'
			},
			reset() {
				this.form_error = {
					tipo            : false,
					categoria       : false,
					cuarto          : false,
					ingreso         : false,
					salida          : false,
					adultos         : false,
					menores         : false,
					titular         : false,
					primer_apellido : false,
					segundo_apellido: false,
					telefono        : false,
					email           : false,
					agencia         : false

				}
			}
		},
		mounted() {
			this.$store.state.loading = false;
			if(empty(this.$store.getters.habitaciones)){
				this.$router.push({name:'calendario'});
			}else{
				if(this.$store.state.create_data.date) {
					let date = this.$store.state.create_data.date.split('-');
					this.selected.ingreso =  new Date(date[0], date[1] - 1, date[2]);
					let salida = new Date(date[0], date[1] - 1, date[2]);
					this.selected.salida = salida.setDate(salida.getDate() + 1);
					this.$store.state.create_data.date = null;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.md-steppers {
		position: relative;
		height: calc(100vh - 115px);
		overflow: auto;

		.md-input.md-select-value{
			padding-left: 10px;
		}
		.details {
			font-size: 14px;
			border: 1px dashed  dimgrey;
			.md-layout-item {
				padding: 7px;
				text-align: center;

				i{
					font-size: 20px;
				}
				.label {
					font-size: 11px;
					color: rgba(0, 0, 0, 0.54)
				}
			}
		}
		.tarifas{
			margin-top: 30px;
			.md-field {
				width: 100%;
				min-height: 48px;
				margin: 0px 0 11px;
				padding-top: 16px;
				display: flex;
				position: relative;
				font-family: inherit;
			}
		}
		.total{
			.dcto {
				text-align: center;
				margin-bottom: 10px;
				background-color: #ff5252;
				color: white;
				font-size: 14px;
				padding: 7px;
			}

			margin: 50px 0px;
			font-size: 18px;
		}
	}
</style>
